import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import { authApi } from "./apis/authApi";
import { dashboardPageApi } from "./apis/dashboardPageApi";

import { offerDetailPageApi } from "./apis/offerDetailPageApi";
import { applicantDetailPageApi } from "./apis/applicantDetailPageApi";
import { insuranceDetailsApi } from "./apis/insuranceDetailsApi";
import { nomineeDetailsApi } from "./apis/nomineeDetailsApi";
import { mandateApi } from "./apis/mandateApi";
import { eSignApi } from "./apis/eSignApi";
import { congratulationsApi } from "./apis/congratulationsApi";
import { verifyPanCardApi } from "./apis/panVerificationApi";
import { digiKycInitDataApi } from "./apis/digiKycInitApi";
import { digiKycRespApi } from "./apis/digiKycResponse";
import { clientLogsApi } from "./apis/clientLogsApi";
import { userDeviceInfoApi } from "./apis/userDeviceInfoApi";
import { createCallerCodeApi } from "./apis/createCallerCodeApi";

export const store = configureStore({
  reducer: {
    [authApi.reducerPath]: authApi.reducer,
    [dashboardPageApi.reducerPath]: dashboardPageApi.reducer,
    [offerDetailPageApi.reducerPath]: offerDetailPageApi.reducer,
    [applicantDetailPageApi.reducerPath]: applicantDetailPageApi.reducer,
    [insuranceDetailsApi.reducerPath]: insuranceDetailsApi.reducer,
    [nomineeDetailsApi.reducerPath]: nomineeDetailsApi.reducer,
    [mandateApi.reducerPath]: mandateApi.reducer,
    [eSignApi.reducerPath]: eSignApi.reducer,
    [congratulationsApi.reducerPath]: congratulationsApi.reducer,
    [verifyPanCardApi.reducerPath]: verifyPanCardApi.reducer,
    [digiKycInitDataApi.reducerPath]: digiKycInitDataApi.reducer,
    [digiKycRespApi.reducerPath]: digiKycRespApi.reducer,
    [clientLogsApi.reducerPath]: clientLogsApi.reducer,
    [userDeviceInfoApi.reducerPath]: userDeviceInfoApi.reducer,
    [createCallerCodeApi.reducerPath]: createCallerCodeApi.reducer,
  },
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware()
      .concat(authApi.middleware)
      .concat(dashboardPageApi.middleware)
      .concat(offerDetailPageApi.middleware)
      .concat(applicantDetailPageApi.middleware)
      .concat(insuranceDetailsApi.middleware)
      .concat(nomineeDetailsApi.middleware)
      .concat(mandateApi.middleware)
      .concat(eSignApi.middleware)
      .concat(congratulationsApi.middleware)
      .concat(verifyPanCardApi.middleware)
      .concat(digiKycInitDataApi.middleware)
      .concat(digiKycRespApi.middleware)
      .concat(clientLogsApi.middleware)
      .concat(userDeviceInfoApi.middleware)
      .concat(createCallerCodeApi.middleware)
  },
});

setupListeners(store.dispatch);

export {
  useGenerateOtpMutation,
  useVerifyotpMutation,
  useGetContactHintQuery,
} from "./apis/authApi";

export {
  useStageStatusMutation,
  useGetAllOffersQuery,
  useGetLoanInfoQuery,
  useCreateNewLoanMutation,
  useGetEmiDetailsMutation,
} from "./apis/offerDetailPageApi";

export {
  useOldKycDocumentMutation,
  useBankDetailPennyDropMutation,
  useGetHvTokenQuery,
  useFaceMatchMutation,
} from "./apis/applicantDetailPageApi";

export {
  useCreateInsuranceDetailsMutation,
  useUpdateLoanMutation,
} from "./apis/insuranceDetailsApi";

export { useNomineeDetailsMutation } from "./apis/nomineeDetailsApi";

export {
  useCreateMandateMutation,
  useMandateStatusMutation,
} from "./apis/mandateApi";

export {
  useFetchESignDocMutation,
  useSendOtpMutation,
  useVerifyOtpMutation,
  useESignDocQuery,
  useESignDisburseMutation,
  useESignKFSMutation,
} from "./apis/eSignApi";

export {
  useFetchSanctionLetterDocMutation,
  useUserFeedbackMutation,
} from "./apis/congratulationsApi";

export {
  useClientLogsMutation
} from "./apis/clientLogsApi";

export {
  useUserDeviceInfoMutation
} from "./apis/userDeviceInfoApi";

export {
  useCreateCallerCodeMutation
} from "./apis/createCallerCodeApi";

export { useDigiKycInitMutation } from "./apis/digiKycInitApi";

export { useDigiKycRespMutation } from "./apis/digiKycResponse";
export { useVerifyPanMutation } from "./apis/panVerificationApi";

export { useGetDashboardQuery } from "./apis/dashboardPageApi";
