import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const dashboardPageApi = createApi({
  reducerPath: "dashboardPage",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.NEXT_PUBLIC_AUTH_URL}`,
    prepareHeaders: async (headers) => {
      const token = await sessionStorage.getItem("token");
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      headers.set("Accept", "application/json; version=3.0");
      headers.set("Source", "LPLWEB");
      return headers;
    },
  }),
  endpoints: (builder) => {
    return {
      getDashboard: builder.query({
        query: (appId) => {
          return {
            url: `/herofin-service/home/${appId}/`,
            method: "GET",
          };
        },
      }),
    };
  },
});
export const { useGetDashboardQuery } = dashboardPageApi;
export { dashboardPageApi };
