import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const applicantDetailPageApi = createApi({
  reducerPath: "applicantDetail",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.NEXT_PUBLIC_AUTH_URL}`,
    prepareHeaders: async (headers) => {
      const token = await sessionStorage.getItem("token");
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      headers.set("Accept", "application/json; version=3.0");
      headers.set("Source", "LPLWEB");
      return headers;
    },
  }),
  endpoints: (builder) => {
    return {
      oldKycDocument: builder.mutation({
        query: (body) => {
          return {
            url: "/cross-sell/old_kyc_documents/",
            method: "POST",
            body,
          };
        },
      }),
      getHvToken: builder.query({
        query: () => {
          return {
            url: `/cross-sell/hv_token/`,
            method: "GET",
          };
        },
      }),

      faceMatch: builder.mutation({
        query: (body) => {
          return {
            url: "/cross-sell/face_match/",
            method: "POST",
            body,
          };
        },
      }),

      bankDetailPennyDrop: builder.mutation({
        query: (body) => {
          return {
            url: "/cross-sell/bank_detail_penny_drop/",
            method: "POST",
            body,
          };
        },
      }),
      getHvToken: builder.query({
        query: () => {
          return {
            url: `/cross-sell/hv_token/`,
            method: "GET",
          };
        },
      }),
    };
  },
});
export const {
  useOldKycDocumentMutation,
  useBankDetailPennyDropMutation,
  useGetHvTokenQuery,
  useFaceMatchMutation,
} = applicantDetailPageApi;
export { applicantDetailPageApi };
