import bank from "/public/bank.svg";
import person from "/public/person.svg";
import fingerprint from "/public/fingerprint.svg";
import verticalDivider from "/public/verticalDivider.svg";
import shield from "/public/shield.svg";
import leftCurveArrow from "/public/left_curve_arrow.svg";
import rightCurveArrow from "/public/right_curve_arrow.svg";
import questionMark from "/public/question_mark.svg";
import brandLogo from "/public/logo.svg";
import homeLocation from "/public/home_location_Icon.svg";
import infoIcon from "/public/info_icon.svg";
import appreciation from "/public/appreciation.svg";
import downArrow from "/public/arrow_down.svg";
import upArrow from "/public/arrow_up.svg";
import goldCoins from "/public/goldcard.svg";
import whiteBank from "/public/ic_select_bank.svg";
import bankDivider from "/public/bankDivider.svg";
import whiteFinger from "/public/fingerprint_white.svg";
import aadhaarLink from "/public/aadhaar_link.svg";
import lighting from "/public/light.svg";
import webCam from "/public/webcam.svg";
import hatGlass from "/public/hatGlass.svg";
import picture from "/public/picture.svg";
import kotakLife from "/public/kotak_bank.svg";
import virus from "/public/covid_icon.svg";
import congratulations from "/public/ic-congratulations.svg";
import donwloadApp from "/public/download_google.svg";
import moneyPouch from "/public/money_pouch.svg";
import secureLoan from "/public/secure_loan.svg";
import IplLogo from "/public/Ipl_logo.png";
import starImg from "/public/starimg.png";
import congratsBanner from "/public/congrats_banner.png";
import polling from "/public/polling_animation.gif";
import unlockLoan from "/public/Banner_button.gif";
import seperatorLine from "/public/seperator_line_icon.svg";
import uncheckTickIcon from "/public/uncheck_tick_icon.svg";
import greenTickIcon from "/public/green_tick_icon.svg";
import moneyBagIcon from "/public/money.svg";
import bankIcon from "/public/bank_icon.svg";
import infoIconDarkOrange from "/public/info_icon_dark_orange.svg";
import redCrossIcon from "/public/red_cross_icon.svg";
import callerIcon from "/public/caller_icon.svg";
import callerModalIcon from "/public/caller_modal_icon.svg";
import exclamationMarkIcon from "/public/exclamation_mark.svg";

export const webColors = {
  primary: "#174035",
  secondary: "#47EE40",
  pale: "#EDFFE8",
  orange: "#FA4616",
  fadeColor: "#989898",
  black: "#000",
  blue: "#407BEF",
  textFadeColor: "rgba(0, 0, 0, 0.38)",
  limeGreen: "#009640",
};

//SVG IMAGES
export const SvgImages = {
  bank,
  person,
  fingerprint,
  verticalDivider,
  shield,
  leftCurveArrow,
  rightCurveArrow,
  questionMark,
  brandLogo,
  homeLocation,
  infoIcon,
  appreciation,
  downArrow,
  upArrow,
  whiteFinger,
  whiteBank,
  bankDivider,
  aadhaarLink,
  goldCoins,
  lighting,
  hatGlass,
  webCam,
  kotakLife,
  virus,
  picture,
  donwloadApp,
  moneyPouch,
  secureLoan,
  congratulations,
  IplLogo,
  starImg,
  congratsBanner,
  seperatorLine,
  uncheckTickIcon,
  greenTickIcon,
  moneyBagIcon,
  bankIcon,
  infoIconDarkOrange,
  redCrossIcon,
  callerIcon,
  callerModalIcon,
  exclamationMarkIcon,
};

//GIFS
export const GIFS = {
  polling,
  unlockLoan,
};

//FACE VERIFICATION INSTRUCTIONS
export const FaceVerificationSteps = [
  {
    text: "Find a place with proper lighting.",
    icon: SvgImages.lighting,
    borderRequired: true,
  },
  {
    text: "Ensure you are not wearing any glasses or hat/cap.",
    icon: SvgImages.hatGlass,
    borderRequired: true,
  },
  {
    text: "Keep the webcam in front of you.",
    icon: SvgImages.webCam,
    borderRequired: true,
  },
  {
    text: "Ensure, the selfie should not be a photo of an existing photo.",
    icon: SvgImages.picture,
    borderRequired: false,
  },
];

//LOAN SANCTION DISPLAY DATA
export const LoanSanctionSteps = [
  {
    text: "Personal Details",
    subText: "Provide your Basic Personal Details.",
    icon: SvgImages.person,
  },
  {
    text: "Banking & KYC",
    subText:
      "Provide your Banking Details & Complete your KYC verification with your selfie.",
    icon: SvgImages.bank,
  },
  {
    text: "Repayment & E-Sign",
    subText: "Mandate & Sign your loan document Digitally",
    icon: SvgImages.fingerprint,
  },
];
//Bank Verification instructions
export const BankVerificationSteps = [
  {
    text: "Select Bank",
    icon: SvgImages.whiteBank,
  },
  {
    text: "Verify Bank Statement via Net Banking",
    icon: SvgImages.whiteFinger,
  },
];

//Gender
export const GendersMenu = [
  { name: "Male", value: "MALE" },
  { name: "Female", value: "FEMALE" },
];

export const currencyIcon = "₹";
//Number Formats
export const CurrencyFormat = (amount) => {
  var x = Number(amount);
  x = x.toString();
  var lastThree = x.substring(x.length - 3);
  var otherNumbers = x.substring(0, x.length - 3);
  if (otherNumbers != "") lastThree = "," + lastThree;
  var res = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree;
  return `${currencyIcon} ${res}`;
};

//MASK
export const MaskStringUptoLastChars = (str) => {
  if (str.length <= 4) {
    return str;
  } else {
    const maskedPart = "*".repeat(str.length - 4);
    const lastPart = str.slice(-4);
    return maskedPart + lastPart;
  }
};

//REGULAR EXPRESSIONS
//Mobile Regex
export const MOBILE_REGEX = (value) => {
  const regex = /^[0-9\b]+$/;
  if (value.length <= 10) return value === "" || regex.test(value);
};
//AADHAR Regex
export const AADHAR_REGEX = (value) => {
  const regex = /^[0-9\b]+$/;
  if (value.length <= 12) return value === "" || regex.test(value);
};
//Name Regex
export const NAME_REGEX = (value) => {
  const regex = /^[a-zA-Z\b]+$/;
  if (value.length <= 25) return value === "" || regex.test(value);
};
//EMAIL Regex
export const EMAIL_REGEX = (value) => {
  // const regex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}$/;
  const regex = /^[[0-9a-zA-Z@.\b]+$/;
  return value === "" || regex.test(value);
};
//Remarks Regex
export const FREE_TEXT_REGEX = (value) => {
  const regex = /^[a-zA-Z0-9 \s!@#$%^&*()_+={}[\]:;,.?~\\/-\s]*$/;
  return value === "" || regex.test(value);
};
//PAN Regex
export const PAN_REGEX = (value) => {
  const regex =
    /^([A-Z]([A-Z]([A-Z]([A-Z]([A-Z]([0-9]([0-9]([0-9]([0-9]([A-Z])?)?)?)?)?)?)?)?)?)?$/;
  return value === "" || regex.test(value);
};
//Password Regex
export const PASSWORD_REGEX = (value) => {
  const passwordRegex = /^[a-zA-Z0-9\s!@#$%^&*()_+={}[\]:;,.?~\\/-]+$/;
  return value === "" || passwordRegex.test(value);
};

//Number Regex
export const NUMBER_REGEX = (value) => {
  const regex = /^[0-9\b]+$/;
  return value === "" || regex.test(value);
};

export const NAME_REGEX_WITH_SPACE = (value) => {
  // Check if the value starts with a space
  if (value.startsWith(' ')) {
    return false;
  }

  const regex = /^[a-zA-Z\s]+$/;
  if (value.length <= 25) return value === "" || regex.test(value);
};

export const PAN_CARD_REGEX = /^[A-Z0-9]*$/;

export const relationshipWithNominee = [
  {
    value: "Mother",
    label: "Mother",
  },
  {
    value: "Father",
    label: "Father",
  },
  {
    value: "Brother",
    label: "Brother",
  },
  {
    value: "Sister",
    label: "Sister",
  },
  {
    label: "Spouse",
    value: "Spouse",
  },
];

export const gender = [
  {
    value: "male",
    label: "Male",
  },
  {
    value: "female",
    label: "Female",
  },
  {
    value: "others",
    label: "Others",
  },
];

export const errorMessageText = "Something went wrong!";

export const lplStageNames = {
  FLOW_STARTED: "Flow_Started",
  ADDRESS_CONFIRMATION: "address_conformation",
  DIGIKYC: "digikyc",
  DIGIKYC_FULL: "digikyc_full",
  PAN_VERIFICATION: "pan_verification",
  BANK_ACCOUNT_DETAISLS: "bank_account_details",
  CHANGE_BANK_DETAILS: "change_bank_details",
  INSURANCE_DETAILS: "insurance_details",
  NOMINEE_DETAILS: "nominee_details",
  CREATE_MANDATE: "create_mandate",
  MANDATE_STATUS_CHECK: "mandate_status_check",
  ESIGN: "e_sign",
  CONGRATULATION: "congratulation",
}
