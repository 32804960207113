import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const offerDetailPageApi = createApi({
  reducerPath: "offerDetail",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.NEXT_PUBLIC_AUTH_URL}`,
    prepareHeaders: async (headers) => {
      const token = await sessionStorage.getItem("token");
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      headers.set("Accept", "application/json; version=3.0");
      headers.set("Source", "LPLWEB");
      return headers;
    },
  }),
  endpoints: (builder) => {
    return {
      stageStatus: builder.mutation({
        query: (body) => {
          return {
            url: "/cross-sell/stage_status/",
            method: "POST",
            body,
          };
        },
      }),

      getAllOffers: builder.query({
        query: () => {
          return {
            url: "/accounts/all-offers/",
            method: "GET",
          };
        },
      }),

      getLoanInfo: builder.query({
        query: () => {
          return {
            url: "/cross-sell/loan-info",
            method: "GET",
          };
        },
      }),

      getEmiDetails: builder.mutation({
        query: (body) => {
          return {
            url: "/cross-sell/get-emi-details/",
            method: "POST",
            body,
          };
        },
      }),

      createNewLoan: builder.mutation({
        query: (body) => {
          return {
            url: "/cross-sell/create_new_loan_application/",
            method: "POST",
            body,
          };
        },
      }),
    };
  },
});
export const {
  useStageStatusMutation,
  useGetAllOffersQuery,
  useGetLoanInfoQuery,
  useCreateNewLoanMutation,
  useGetEmiDetailsMutation,
} = offerDetailPageApi;
export { offerDetailPageApi };
