import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const authApi = createApi({
  reducerPath: "auth",
  // tagTypes: ["application", "customer"],
  baseQuery: fetchBaseQuery({
    baseUrl: `/api`,
  }),
  refetchOnReconnect: true,
  keepUnusedDataFor: 2,
  endpoints: (builder) => {
    return {
      getContactHint: builder.query({
        query: (id) => {
          return {
            url: `/getContactHint/${id}`,
            method: "GET",
          };
        },
      }),
      generateOtp: builder.mutation({
        query: (body) => {
          return {
            url: "/generateOtp",
            method: "POST",
            body,
          };
        },
      }),
      verifyotp: builder.mutation({
        query: (body) => {
          return {
            url: "/verifyOtp",
            method: "POST",
            body,
          };
        },
        // invalidatesTags: ["application", "customer"],
      }),
    };
  },
});

export const {
  useGenerateOtpMutation,
  useVerifyotpMutation,
  useGetContactHintQuery,
} = authApi;
export { authApi };
