import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const createCallerCodeApi = createApi({
  reducerPath: "createCallerCode",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.NEXT_PUBLIC_AUTH_URL}`,
    prepareHeaders: async (headers) => {
      const token = await sessionStorage.getItem("token");
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      headers.set("Accept", "application/json; version=3.0");
      headers.set("Source", "LPLWEB");
      return headers;
    },
  }),
  refetchOnReconnect: true,
  endpoints: (builder) => {
    return {
      createCallerCode: builder.mutation({
        query: (body) => {
          return {
            url: "/cross-sell/create_caller_code/",
            method: "POST",
            body,
          };
        },
      }),
    };
  },
});

export const {
  useCreateCallerCodeMutation,
} = createCallerCodeApi;

export { createCallerCodeApi };
